"use client";
import { useTranslations } from "next-intl";

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
    const t = useTranslations("Error");

    console.error(error);

    return (
        <div className="mt-8 mb-8 flex flex-col items-center justify-center px-4 text-center">
            <h2 className="mb-4 text-2xl font-bold">{t("title")}</h2>
            <div className="mb-4">{t("description")}</div>
            <div className="text-red-800">{error.message}</div>
        </div>
    );
}
